* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: sans-serif;

}

/* --------- Variables Css ------------ */


:root {
  --header-height: 4rem;

/* Margins */
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.2rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;

  /* Z Index */
  --z-tooltip: 10;
  --z-fixed: 100;

  /* Colors */
  --golden-yellow-light:#e2c070;
  --golden-yellow: #deb887;
  --black-black: #231f20;
  --coffee-brown: #a87a56;
  --coffee-blue: #443f73;
  --coffee-red: #b81c36;
}

ul {
  padding: 0;
}

/*========== NAV ==========*/

.nav {
  height: 4rem;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.nav__item__btns{
  margin-right: 0;
}

.nav__logo {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.nav__link{
  margin-left: 10px;
}

.nav__logo img {
  margin: 160px 30px 30px 50px;
  width: 80%;
}
.nav__menu {
  display: flex;
  margin: 0 auto;
  z-index: 1000;
  position: sticky;
}

.LenguageButton {
  padding: 0.3rem 0.7rem;
  background-color: var(--black-black);
  font-size: 0.8rem;
  color: var(--golden-yellow);
  border: var(--black-black);
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin-right: 10px;
  margin-left: 10px;
}

.LenguageButton:hover {
  background-color: var(--golden-yellow);
  color: var(--black-black);
}
.Login--button{
  padding: 0.3rem 0.7rem;
  background-color: var(--black-black);
  font-size: 0.8rem;
  color: var(--golden-yellow);
  line-height: 1;
  border: var(--black-black);
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin-left: 5px;
}

.Login--button:hover{
  background-color: var(--golden-yellow);
  color: var(--black-black);
}


.shopbutton--home{
  padding: 1rem  3rem;
  background-color: var(--golden-yellow-light);
  font-size: 0.8rem;
  color: var(--black-black);
  line-height: 1;
  border: var(--black-black);
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin-left: 5px;

}

.shopbutton--home:hover{
  background-color: var(--black-black);
  color: #e2c070;
}

.Typica-articule{
  display: flex;
  flex-wrap: wrap;
  min-width: 300px;
  justify-content: center;
}
.cart-item{
  color: var(--golden-yellow) ;
  font-weight: bold;
  text-align: center;
}
.nav-logo-shop{
  margin: 0px;
}
.nav-logo-shop img{
  width: 50%;
  
}

/* ---------------- Tablets ------------------ */

@media (min-width: 769px) {

/*-------- NavBar -----*/
  .nav {
    width: 100%;
    height: 4rem + 1rem;
    justify-content: initial;
  }

  .nav__menu {
    margin-left: auto;
    z-index: 1000;
    position: sticky;


    
  }

  .nav__list {
    display: flex;
  }

  .nav__item {
    margin-top: 30px;
    display: flex;
    margin-bottom: 0;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .nav__toggle {
    display: none;
    
  }



/*---- Home Page ----*/


  .Home--container{
    min-width: 320px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .Home--title--landing{
    margin-top: 20px;
  }

  .Home--PageLogo{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
  }

  .Home--page--title{
    display: flex;
    color: #e2c070;
    font-size: 1.5rem;
    font-size: 1.5rem;
    width: 500px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  


}

/* ----------- Desktop  ----------------*/


@media (min-width: 1024px) {

  
/* ----  Farm ----- */
  .nav__list {
    display: flex;
    margin-top: 25px;
  }

.Gladys--info--container{
  margin-left: 70px;
}

  /* Home Page*/
  .nav {
    width: 100%;
    height: 4rem + 1rem;
    justify-content: initial;
  }
  


  .nav__menu {
    margin-left: auto;
    z-index: 1000;
    position: sticky;


  }

  .nav__list {
    display: flex;
    justify-content: center;
    align-self: center;
  }
  .nav__item {
    margin-left: 15px;
    margin-bottom: 0;
  }
  .nav__toggle {
    display: none;
  }

  .nav__logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
 



  /*------------- Home page ---------------*/

  .Home--container{
    min-width: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 0;
    height: 600px;

  }
  .Home--PageLogo{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
  }

  .Home--page--title{
    display: flex;
    color: #e2c070;
    width: 500px;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  


  #nav-toggle {
    display: none;
  }
}

@media (max-width: 768px) {
  .nav__menu {
    display: none;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(./assets/images/CoffeBackground.jpg);
    background-position: center;
    background-size: cover;
    z-index: 1000;
    position: sticky;


  }

  .nav__landing {
    display: none;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(./assets/images/HappyFamily.jpeg);
    background-position: center;
    background-size: cover;
  }
  .nav__farm{
    display: none;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(./assets/images/FarmPhoto.png);
    background-position: center;
    background-size: cover;

  }
  .nav__menu__shop {
    display: none;
    background-color: black;
    height: 100%; 
    position: sticky;
  }
  .nav__logo{
    margin: 0 auto;
  }
  .nav__item {
    margin: 40px;
  }
  #hidden {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 5rem;
    width: 100%;
    height: 100%;
    transition: 0.1s;
    margin-top: 25px;
    z-index: 2;
  }
  #hidden-shop {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 5rem;
    width: 100%;
    height: 100%;
    transition: 0.1s;
    margin-top: 25px;
    z-index: 2;
  }

  .nav__team {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(./assets/images/TeamTeam.jpeg);
    background-position: center;
    background-size: cover;
  }


  .Home--page--title{
    display: flex;
    color: #e2c070;
    height: 100%;
    width: 300px;
    text-align: center;
  }
 
  .Home--container{
    min-width: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 150px;
    padding-bottom: 250px;
  }
  .Home--PageLogo{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    order: 2;
  }

  .Home--page--title{
    display: flex;
    color: #e2c070;
    width: 300px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    order: 1;
  }


  .services__content {
    text-align: center;
    max-width: 220px;
    line-height: 25px;
  }

  /*----- Store -----*/
  .Container-shop-info-Geisha{
    order: 2;
  }
  .Container-shop-image-Geisha{
    order: 1;
  }
  .Container-shop-info-Blend{
  order: 2;
  }
  .Container-shop-image-blend{
    order: 1;

  }
  

}

  

.nav__link {
  color: var(--golden-yellow);
  font-weight: bold;
  align-self: center;
  text-align: center;
}

.nav__link__login{
  color: var(--golden-yellow);
  font-weight: bold;
  align-self: center;
  text-align: center;
}




.nav__toggle {
  cursor: pointer;
}

.nav__list {
  align-self: center;
  text-align: center;
  margin-top: 85px;
}


/*------------ Home Page-----------------*/

.home--page {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
  url(./assets/images/CoffeBackground.jpg);
  background-position: center;
  background-size: cover;
}


.Home--PageLogo img {
  width: 100%;
}


/*------------- Services Section -------------*/
.services--section {
  height: 50%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  display: flex;
  padding-top: 30px;
}

.services__container {
  display: flex;
  align-self: center;
  flex-wrap: wrap;
  justify-content: center;
}

.services__content {
  text-align: center;
  max-width: 220px;
  line-height: 25px;
  margin: 70px 30px 30px 30px;
}
.services-coffe{
  margin-bottom: 50px;
}

.services__img {
  width: 64px;
}

.section--services--title {
  margin-top: 30px;
  color: var(--golden-yellow);
  height: 45px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 3rem;
}

.services__title {
  margin-bottom: 11px;
  color: white;
}

.services__description {
  color: white;
}



/*----------- Contact Us Section ------------- */

.Container-contact-services{
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
  url(./assets/images/CoffeBackground.jpg);
  background-position: center;
  background-size: cover;
}
.container--form {

  height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.form-principal-container {
  width: 100%;
  justify-content: center;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  min-width: 320px;
}

.contact-form {
  background-color: var(--black-black);
  display: flex;
  justify-content: center;
  padding: 2.3rem 2.2rem;
  width: 320px;
  border-radius: 20px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 320px;
  padding: 2rem;
  background-color: var(--golden-yellow);
  border-radius: 20px;
} 
.Contact-logo-container{
 
  margin-top: 30px;
}

.Contact-logo-container img{
  width: 80%;
 
}




.info-contact-form a{
  font-size: 1rem;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.Label-form{
  color: #fafafa;
  font-size: 0.9rem;
}



.btn {
  padding: 0.6rem 1.3rem;
  background-color: var(--black-black);
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  line-height: 1;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  color: white;
}



.btn:hover {
  background-color: transparent;
  color: var(--golden-yellow);
}

.Label-form{
  margin-left: 10px;
}






.contact-info .title {
  color: var(--black-black);
  padding-bottom: 2rem;
}

.title{
  text-align: center;
}

.information {
  display: flex;
  flex-direction: column;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}



.social-media {
  padding: 2rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.social-media p {
  color: #333;
  margin-right: 7px;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
  justify-content: center;
}

.social-icons a {
  width: 37px;
  height: 37px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
  display: flex;
  justify-content: center;
}

.social-icons img{
  width: 65%;
}
.social-icons a:hover {
  transform: scale(1.05);
}
.whatsapp--button img{
  width: 80%;
}
.whatsapp--button {
  border: none;
  background: var(--black-black);
  width: 36.5px;
  margin: 10px;
  outline: none;
  transition: 0.3s;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.2rem;
  cursor: pointer;
  height: 36.5px;
  display: flex;
  align-items: center;
}

.whatsapp--button img{
  width: 80%;
  margin-top: 5px;
  margin-left: 3px;
}

.facebook{
  background-color: var(--black-black);
}

.instagram{
  background-color: var(--black-black);
}
.instagram:hover{
  background: #8A2387;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #F27121, #E94057, #8A2387);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #F27121, #E94057, #8A2387); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.facebook:hover{
  background-color: #3b5998 ;
}


.whatsapp--button:hover {
  background: #25d366;
  transform: scale(1.05);

}






/*----Footer Section ------*/

footer {
  background-color: #231f20;
}
.footer__container {
  display: flex;
}

.footer__logo img {
  width: 50%;
}

.footer__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #231f20;
}

.footer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 250px;
  margin: 20px;
  color: white;
}

.footer__content a {
  color: white;
}
#scaplogo{
  width: 200px;
}
.footer__logo {
  font-size: var(--h3-font-size);
  color: var(--first-color);
  font-weight: var(--font-semi-bold);
}

.footer__description {
  display: block;
  font-size: var(--small-font-size);
  margin: 0.25rem 0 var(--mb-3);
}

.footer__social {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-right: var(--mb-2);
}

.footer__title {
  font-size: 1.5rem;
  color: var(--golden-yellow);
  margin-bottom: var(--mb-2);
}

.footer__link {
  display: inline-block;
  color: var(--text-color);
  margin-bottom: var(--mb-1);
}

.footer__link:hover {
  color: var(--golden-yellow);
}

.footer__copy {
  text-align: center;
  font-size: var(--small-font-size);
  color: white;
  margin-top: 3.5rem;
}

               /* ------------------- HISTORY / TEAM / FARMS / SLIDER / GOOGLE SIGN / SHOP  ----------------- */



/*-------------FARMS----------------*/

.home--page--farms {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(./assets/images/FarmPhoto.png);
  background-position: center;
  background-size: cover;
}
.Nav--farms{
  display: flex;
  justify-content: center;
}

.row {
  display: flex;
  height: 88%;
  align-items: center;
  flex-wrap: wrap;
}

.col {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 50px;
}
.col h1 {
  color: var(--golden-yellow);
  font-weight: bolder;
  text-align: center;
  line-height: 50px;
}

.col h1 {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 4rem;
}

.p--tag--farm {
  color: var(--golden-yellow);
  font-weight: bolder;
  text-align: center;
  line-height: 30px;
  font-size: 1.4rem;
  padding-left: 10px;
  padding-right: 10px;
}



.Section--farms--container{
  background-color: var(--black-black);

}

.Gladys--info--container{
  margin-top: 35px;
}

.Farms--title{
  margin-bottom: 20px;
}
.Container--Gladys{
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  
}
.Gladys--info--container{
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--golden-yellow-light);
  text-align: center;
  font-weight: bold;

}


.image-gladys{
  width: 290px;
  text-align: center;
  margin-top: 30px;
  border-radius: 30px;
 

}

.right-arrow-farm {
  position: absolute;
  top: 50%;
  right: 5%;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  color:var(--coffee-red);

}

.left-arrow-farm {
  position: absolute;
  top: 50%;
  left: 5%;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  color:var(--coffee-red);

}
.pin img{
  width: 10%;
  padding-top: 20px;
}

.Info-order{
  order: 0;
}

.Farm--Logo--Container img{
  width: 75%;
}




/*---------Team Section-----------*/

.home--page--team {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(./assets/images/TeamTeam.jpeg);
  background-position: center;
  background-size: cover;
}
.Landing--Home--team {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  font-size: 3rem;
  color: var(--golden-yellow);
  text-align: center;
}

.Section--Team--Container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--black-black);
}

.Team--container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  min-width: 320px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.team {
  display: flex;
  width: 600px;
  height: 200px;
}

.info {
  width: 100%;
  height: 200px;
  color: var(--black-black);
  font-weight: bold;
  text-align: center;
}

.info h2 {
  margin-bottom: 30px;
}


.info p {
  padding-left: 20px;
  padding-right: 20px;
}

.infocard1 {
  display: flex;
  flex-direction: column;
  background: var(--coffee-brown);
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  line-height: 22px;
}

.infocard2 {
  display: flex;
  flex-direction: column;
  background: var(--coffee-brown);
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.infocard3 {
  display: flex;
  flex-direction: column;
  background: var(--coffee-brown);
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  order: 1;
}
.infocard4 {
  display: flex;
  flex-direction: column;
  background: var(--coffee-brown);
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  order: 1;
}
.infocard5 {
  display: flex;
  flex-direction: column;
  background: var(--coffee-brown);
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}
.infocard6 {
  display: flex;
  flex-direction: column;
  background: var(--coffee-brown);
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.team3 {
  background-image: url(./assets/images/3generation.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  order: 2;
  justify-content: center;
}
.team4 {
  background-image: url(./assets/images/Ceo2.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  order: 2;
}
.team5 {
  background-image: url(./assets/images/cristina.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 230px;
  background-position: center;
  display: flex;
  justify-content: center;
}
.team6 {
  background-image: url(./assets/images/denia.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
}

.team7 {
  background-image: url(./assets/images/titi.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
}

.team8 {
  background-image: url(./assets/images/martin.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
}


/*------History Section-------*/

#Exports--img{
  width: 75%;

}

.home--page--history {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(./assets/images/HappyFamily.jpeg);
  background-position: center;
  background-size: cover;
  width: 100%;
  overflow-y: hidden;
}

.Landing--Home--history {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  font-size: 3rem;
  color: var(--golden-yellow);
  text-align: center;
  padding-bottom: 350px;
  overflow-y: hidden;
}
.Title--History--Container {
  align-self: center;
  justify-content: center;
  margin-top: 200px;
  width: 75%;
}

.container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  background-color: var(--black-black);
}
.timeline {
  width: 80%;
  height: auto;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  text-align: center;
}
.timeline img {
  border-radius: 50px;
}

.timeline ul {
  list-style: none;
}
.timeline ul li {
  padding: 20px;
  background-color: var(--black-black);
  color: white;
  border-radius: 50px;
}
.timeline ul li:last-child {
  margin-bottom: 0;
}
.timeline-content h1 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 10px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  color: var(--black-black);
}
.timeline-content p {
  font-size: 1.4rem;
  line-height: 30px;
  font-weight: 300;
  align-self: center;
  justify-self: center;
  color: burlywood;
  text-align: initial;
}
.timeline-content .date {
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: burlywood;
}

@media only screen and (min-width: 768px) {
  .timeline:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 25px;
    border-radius: 30px;
    height: 100%;
    background-color: #333;
  }

  .timeline-content .date {
    font-size: 1rem;
  }
  .timeline ul li {
    width: 50%;
    position: relative;
    margin-bottom: 50px;
  }
  .timeline ul li:nth-child(odd) {
    float: left;
    clear: right;
    transform: translateX(-30px);
    border-radius: 50px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .timeline ul li:nth-child(even) {
    float: left;
    clear: right;
    transform: translateX(30px);
    border-radius: 50px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .timeline ul li::before {
    color: #333;
    position: absolute;
    height: 80px;
    border-radius: 100%;
    width: 80px;
    background-color: burlywood;
    top: 0px;
  }
  li.firstcard::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "1997";
    color: #333;
    font-size: 1.9rem;
  }
  li.secondcard::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "1880";
    color: #333;
    font-size: 1.9rem;
  }

  li.threecard::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "1914";
    color: #333;
    font-size: 1.9rem;
  }
  li.fourcard::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "1991";
    color: #333;
    font-size: 1.9rem;
  }

  li.fivecard::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "1993";
    color: #333;
    font-size: 1.9rem;
  }
  li.sixcard::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "1997";
    color: #333;
    font-size: 1.9rem;
  }
  li.sevencard::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "1998";
    color: #333;
    font-size: 1.9rem;
  }
  li.eightcard::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "1998";
    color: #333;
    font-size: 1.9rem;
  }
  li.ninecard::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "1998";
    color: #333;
    font-size: 1.9rem;
  }

  li.tencard::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "1999";
    color: #333;
    font-size: 1.9rem;
  }

  li.twelvecard::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: "2020";
    color: #333;
    font-size: 1.9rem;
  }
  li.elevencard::before{
    content: "2000";
  }
  .timeline ul li:nth-child(odd)::before {
    transform: translate(50%, -50%);
    right: -30px;
  }
  .timeline ul li:nth-child(even)::before {
    transform: translate(-50%, -50%);
    left: -30px;
  }
  .timeline-content .date {
    position: absolute;
    top: -30px;
  }
  .timeline ul li:hover::before {
    background-color: burlywood;
  }

  .date-year {
    display: none;
  }
  

  /* Farms Section */
  .image-gladys{
    width: 430px;
    height: 450px;
  }

  .Gladys--info--container{
    width: 450px;
    height: 100%;
    display: flex;
    color: var(--golden-yellow-light);
    font-weight: bold;

  }
 

}

.firstcard h1 {
  color: burlywood;
}
.threecard img {
  width: 100%;
}

.firstcard img {
  width: 100%;
}

.secondcard img {
  width: 100%;
}
.fourcard img {
  width: 100%;
}
.sixcard img {
  width: 100%;
}
.fivecard img {
  width: 100%;
}
.sevencard img {
  width: 100%;
}

.eightcard img {
  width: 75%;
}

.ninecard img {
  width: 100%;
}

/*------------- Google Login-----------------*/


.Login-Container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: black ;

}

.Google-Card{
  color: var(--golden-yellow-light);
  font-size: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  margin: 50px;
  border-radius: 20px;
  
}

.Google-Card img{
  width: 500px;
  height: 100%;
}
.Google-btn{
  padding: 0.3rem 0.7rem;
  background-color: var(--black-black);
  font-size: 0.8rem;
  line-height: 1;
  border: var(--black-black);
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  align-self: center;
  width: 220px;
  height: 40px;
  transition: 0.3s;
  margin: 0;
  color: var(--golden-yellow-light);
  font-weight: bold;
}

.Google-Card-Image{
  width: 300px;
  align-self: center;
  
}

.Login--logo{
  display: flex;
  padding-top: 50px;
  width: 75%;
  align-self: center;
}


.Google-btn:hover{
  background-color: var(--golden-yellow-light);
  color: var(--black-black);  
}

/*------ Shop section ------*/

.Slider-title{
  text-align: center;
  margin-top: 50px;
}

.Shop-slider{
  background-color: black;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  align-content: center;
  flex-wrap: wrap;
  padding-bottom: 150px;
  padding-top: 100px;
}
.Title-slidershop-container{
  display: flex;
  background-color: black;
  justify-content: center;
}

.store--logo{
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 75px;  
}

.Title--Shop--Container{
  display: flex;
  background-color: #f5f5f5;
  justify-content: center;

}

.Toasted-coffee-title{
  color: var(--golden-yellow);
  text-align: center;
  font-size: 3rem;
  padding-top: 15px;
  width: 300px;
  align-self: center;
}

.Nav--link--store{
  color:var(--golden-yellow);
}

.nav__item__shop{
  color: var(--golden-yellow-light);
}

.nav__linkshop{
  color: var(--golden-yellow);
}
.Landing--shop{
background-color: var(--black-black);
display: flex;
flex-direction: column;
flex-wrap: wrap;
}

.Nav--shop{
  background-color: black;
  display: flex;
  justify-content: center;
}
.Container--shop--articles{
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
  url(./assets/images/CoffeBackground.jpg);
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

}


.Info-coffebox{
  justify-self: flex-start;  
}

.Container-shop-info{
  display: flex;
  flex-direction: column;
  width: 320px;
  color: var(--golden-yellow);
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  font-weight: bold;
  
}
.Container-coffebox-info{
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 100%;
  margin-top: 120px;
  color: var(--golden-yellow);
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  padding-bottom: 30px;
  font-weight: bold;
}


.coffee-box-articule{
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}
.Container--info--title {
  margin-top: 30PX;
  font-size: 2rem;
  color: var(--golden-yellow);
  font-weight: bold;
  
}

.Coffee-boxes img{
  width: 300px;
}

.Container--info--text{
  font-size: 1.2rem;
  width: 300px;
  text-align: center;
}
.Title--Caracteristicas{
  margin-bottom: 30px;
  
}

.li-coffee{
  margin-bottom: 25px;
}

.Coffe-shop-section{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  width: 100%;
  height: 100%;

}

.Coffe-container-articule{
  display: flex;
}


.Container-addtocart{
  margin: 15px;
}

.info--right{
  padding-top: 40px;
}
.Window--title--one{
  text-align: center;
  margin-top: 15px;
  color: var(--golden-yellow);
  font-weight: bold;
}

.Window--title{
  margin-top: 15px;
  color: var(--golden-yellow);
  font-weight: bold;
  text-align: center;

}
.Container-shop-image{
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  min-width:300px;
  flex-direction: column;
}


.Coffee-image{
  display: flex;
  width: 290px;
  height: 350px;
  background-image: url(./assets/icons/Java.png);
  background-position: center;
  background-size: cover;
}
.image-blend{
  background-image: url(./assets/icons/blendicon.png);
  background-position: center;
  background-size: cover;
  width: 89%;
}
.image-typica{
  background-image: url(./assets/icons/typica.png);
  background-position: center;
  background-size: cover;
  width: 60%;
  margin-top: 25px;
}
.image-geisha{
  background-image: url(./assets/icons/geisha.png);
  background-position: center;
  background-size: cover;
  width: 60%;
}


.Coffee-boxes{
  background-image: url(./assets/images/boxes.png);
  background-size:auto;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  
}

.cart--button{
  padding: 10px;
  align-self: center;
  border-radius: 10px;
  cursor: pointer;
  font-family: sans-serif;
  background: var(--black-black);
  transition: 0.8s;
  border: none;
  color: var(--coffee-brown);
  outline: none;
  margin: 10px;
}
.Addtocart--text{
  text-align: center;
  margin: 10px;
  color: var(--golden-yellow);
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.Cupon--text{
  text-align: center;
  margin: 10px;
  color: var(--golden-yellow);
  font-size: 1rem;
  margin-bottom: 30px;
  font-weight: bold;

}

.Addtocart--price{
  text-align: center;
  margin: 10px;
  color: var(--golden-yellow);
  font-size: 1rem;
  font-weight: bold;

}

.cart--button:hover{
  background-color: var(--golden-yellow);
  color: var(--black-black);
}

.Java{
  order: 2;
  justify-content: center;
}

.Java-image-container{
  order: 1;
  justify-content: center;

}

.Java-image{
  background-image: url(./assets/icons/typica.png)
}

.Typica-image{
  background-image: url(./assets/icons/Java.png);
}
.Blend-image{
  background-image: url(./assets/icons//typica.png);
}


.Title--características{
  padding-bottom: 10px;
}

/* ------- Info Card Sections ---------- */

.Card--big--container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: var(--black-black); 
  height: 100%;

}

.Container--card--info{
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 50%;
  height: 100%;
  flex-wrap: wrap;
  min-width: 320px;
  margin: 0 auto;
}


.Container--card--info p{
  font-size: 1.5rem;
  color: var(--golden-yellow);
  margin: 0 auto;
}


.Cards--button {
  padding: 0.3rem 0.7rem;
  background-color: var(--black-black);
  font-size: 0.8rem;
  line-height: 1;
  border: var(--black-black);
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  color: var(--golden-yellow);
  font-weight: bold;
}

.Cards--button:hover {
  background-color: var(--golden-yellow);
  color: var(--black-black);
}

.Container--section--cards{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--black-black); 
}

.Title--section--cards{
  font-size: 3rem;
  letter-spacing: .1ch;
  line-height: 1;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  color: var(--golden-yellow);
  margin-top: 50px;
  min-width: 320px;
  
}


.Card--link{
  color: inherit;
  width: 200px;
  height: 300px;
  min-height: 300px;
  perspective: 1000px;
  margin: 1.5rem;
  position: relative;
  cursor: pointer;
  background-image: url(./assets/images/geishawindow.jpeg);
  background-size: cover;
  background-position: center;
  border-radius: 50px;
  box-shadow: 0px 30px 50px 0px black;
}




.Card--back p{
  width: 100%;
  text-align:left;
  color: var(--golden-yellow);
  font-weight: bolder;
}

.Card--front,
.Card--back{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 45px;
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  transition: ease-in-out 600ms;
  transform-style: preserve-3d;
  
}

.Card--title--back{
  margin-bottom: 15px;
  color: var(--golden-yellow);
  font-size: 2rem;
}

.Cards--button{
  margin-top: 15px;
}

.Card--front:before{
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: .25;
  z-index: 1;
}

.Card--link:hover .Card--front{
  transform: rotateY(180deg);
}


.Card--link:nth-child(even):hover .Card--front{
  transform: rotateY(-180deg);
}

.Card--back{
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(./assets/images/whitebeans.jpeg);
    background-position: center;
    background-size: cover;
  transform: rotateY(-180deg);
  padding: 0 2em;
}


.Card--link:hover .Card--back{
  transform: rotateY(0deg);
}

.Card--link:nth-child(even) .Card--back{
  transform:rotateY(180deg);

}


.Card--link:nth-child(even):hover .Card--back{
    transform: rotateY(0deg);
}



.button:hover{
  transform: translateZ(-55px);
}

.button:active{
  transform:translate(20px)

}

/* ----- Slider Section ----- */


.Slider--Background--Container{
  background-color: var(--black-black);
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  align-content: center;
  flex-wrap: wrap;
  padding-top: 30px;
  padding-bottom: 30px;
}

.slider {
  position: relative;
  max-width: 300px;
  display: flex;  
  justify-content: center;
  align-items: center;
  margin: 90px 30px 0px 30px;

}

.image {
  width: 280px;
  height: 486px;
  border-radius: 100px;

}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 1%;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  color:black;

}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 1%;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  color:black;

}



.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

.Slider-title{
  height: 100%;
  color: var(--golden-yellow);
  position: relative;
  top: 75px;
  font-size: 4rem;
}

.Title-slider-container{
  display: flex;
  background-color: var(--black-black);
  justify-content: center;
}



/* ------ Shop Green Coffee Banner ------ */

.Banner-coffeegreen-container{
  width: 100%;
  height: 40vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
  url(./assets/images/whitebeans.jpeg);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding-top: 170px;
  padding-bottom: 170px;
  align-items: center;
}
.BannerGoShop{
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
  url(./assets/images/CoffeBackground.jpg);
  background-position: center;
  background-size: cover;

}
.Articule-coffegreen-info{
  text-align: center;
}

.Coffeegreen-title{
  font-weight: bold;
  color: var(--golden-yellow);
  margin-bottom: 40px;
  font-size: 3rem;
}

.Coffeegreen-text{
  color: var(--golden-yellow);
  font-weight: bold;
  margin-bottom: 20px;
}

.Greencoffee-btn{
  padding: 1rem 2rem;
  background-color: var(--black-black);
  font-size: 0.8rem;
  color: var(--golden-yellow);
  line-height: 1;
  border: var(--black-black);
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  

}

.Greencoffee-btn:hover{
  background-color: var(--golden-yellow);
  color: var(--black-black);
}
/* ------  Shop Banner ------*/


.Shop--banner--container{
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  }

.Image--Banner{
  width: 320px;
  height: 500px;
  background-image: url(./assets/images/bannerthree.jpeg);
  background-size: cover;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 20px;

}

.bannertwo{
  background-image: url(./assets/images/newlogo.jpeg);

}

.bannerthree{
  background-image: url(./assets/images/bannertwo.png);

}


/* ----- Quality Coffee Section ---- */

.Quality--container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: var(--black-black); 
  height: 100%;

}
.Quality-title{
  margin-top: 20px;
}

.Quality-text{
  margin-top: 23px;
}

.Quality--info{
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 50%;
  height: 50%;
  flex-wrap: wrap;
  min-width: 320px;
}
.Quality-info{
  width: 500px;
  color: var(--golden-yellow);
  text-align: center;
  font-size: 1.5rem;
}

.Quality--image{
  width: 500px;
  border-radius: 30px;
  margin-left: 30px;
  margin-right: 30px;
  
}
.Quality--image img {
  width: 100%;
  border-radius: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
 

}